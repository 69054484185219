import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const linkedTasksContainer = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'column',
      '.row': {
        display: 'grid',
        gridTemplateColumns: '4fr 1fr 24px',
        gap: theme.spacing(0.8),
        alignItems: 'center',
        '&__heading': {
          paddingY: theme.spacing(1.6),
          paddingX: theme.spacing(0.8),
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        '&__task': {
          cursor: 'pointer',
          opacity: 1,
          transition: 'all 0.2s linear',
          ':hover': {
            opacity: 0.7,
          },
          borderRadius: theme.spacing(0.6),
          padding: theme.spacing(0.8),
          '&:nth-child(even)': {
            backgroundColor: Colors.GreyLight,
          },
          '.dueDate': {
            textTransform: 'capitalize',
          },
        },
      },
    }),
    [theme],
  );

  return useMemo(() => ({linkedTasksContainer}), [linkedTasksContainer]);
};
