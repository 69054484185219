import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const SEARCHBAR_HEIGHT = '50px';

export const useStyle = () => {
  const theme = useTheme();

  const formContainerBasic: SxProps<Theme> = useMemo(
    () => ({
      mx: 'auto',
      height: SEARCHBAR_HEIGHT,
      borderRadius: '12px',
      border: `1px solid ${Colors.GreyText}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '16px',
      p: theme.spacing(1, 1.6),
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
      backgroundColor: theme.palette.common.white,
      width: '100%',
    }),
    [theme],
  );

  const searchIcon: SxProps<Theme> = useMemo(
    () => ({
      marginRight: theme.spacing(2),
    }),
    [theme],
  );

  const freeSearch: SxProps<Theme> = useMemo(
    () => ({
      '.MuiInputBase-root': {
        p: 0,
      },
      '.MuiInputBase-input': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    }),
    [],
  );

  const tabs = useMemo(
    () => ({
      marginTop: theme.spacing(3.2),
      marginBottom: theme.spacing(1.6),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(0.8),
    }),
    [theme],
  );

  const tabsButtons = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(0.8),
    }),
    [theme],
  );

  const tabSelected = useMemo(
    () => ({
      backgroundColor: Colors.Black,
      padding: theme.spacing(0.6, 0.8, 0.6, 0.8),
      borderRadius: theme.spacing(0.5),
    }),
    [theme],
  );

  const tabSelectable = useMemo(
    () => ({
      cursor: 'pointer',
    }),
    [],
  );

  const hintBox = useMemo(
    () => ({
      marginTop: theme.spacing(1.6),
      marginLeft: theme.spacing(2.4),
    }),
    [theme],
  );

  const noResults = useMemo(
    () => ({
      marginTop: theme.spacing(3.2),
      backgroundColor: Colors.White,
      borderRadius: theme.spacing(1.2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('lg')]: {
        height: theme.spacing(15),
      },
      [theme.breakpoints.up('md')]: {
        height: theme.spacing(15),
      },
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(24),
      },
    }),
    [theme],
  );

  const switchContainer = useMemo(
    () => ({
      display: 'flex',
      gap: theme.spacing(0.8),
      alignItems: 'center',
    }),
    [theme],
  );

  const select = useMemo(
    () => ({
      border: `1px solid ${Colors.Black}`,
      borderRadius: theme.spacing(10.0),
      width: theme.spacing(13.2),
      height: theme.spacing(3.2),
      '& fieldset': {
        border: '0px !important',
      },
      '&.Mui-focused fieldset': {
        border: '0px !important !important',
        borderColor: 'transparent !important',
      },
      '&.Mui-disabled': {
        border: `1px solid ${Colors.Grey700}`,
      },
    }),
    [theme],
  );

  const searchBar = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1.6),
    }),
    [theme],
  );

  const scanButton = useMemo(
    () => ({
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderRadius: '6px',
      width: '108px',
    }),
    [],
  );

  return {
    formContainerBasic,
    searchIcon,
    freeSearch,
    tabs,
    tabsButtons,
    tabSelected,
    tabSelectable,
    hintBox,
    noResults,
    switchContainer,
    select,
    searchBar,
    scanButton,
  };
};
