import React, {FormEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Webcam from 'react-webcam';
import useScanDetection from 'use-scan-detection';

import {Box, Button, IconButton, InputAdornment, TextField} from '@mui/material';
import {
  BrowserMultiFormatReader,
  DecodeHintType,
  BarcodeFormat,
} from '@zxing/library';

import {Dialog} from '@/atoms/Dialog';
import {IconSVG} from '@/atoms/IconSVG';
import {Select} from '@/atoms/Select';
import {SwitchButton} from '@/atoms/SwitchButton';
import {Text} from '@/atoms/Typography/Text';
import {useAuthentication} from '@/store/authentication';
import {Colors} from '@/themes/variables';

import {SearchButton} from './SearchButton';
import {MyAccountSearchBarProps} from './interfaces';
import {useStyle} from './styles';

const LOYALTY_CARD_CHARACTERS = 13;

const hints = new Map<DecodeHintType, BarcodeFormat[]>();
const formats = Object.values(BarcodeFormat).map(el => el as BarcodeFormat);
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
const codeReader = new BrowserMultiFormatReader(hints);

const MyAccountSearchBar = ({
  freeSearch,
  onFreeSearchChange,
  onTabChange,
  onReset,
  onSubmit,
  minChars = 3,
  personas,
  onlyInThisStoreVisible = false,
  onlyInThisStoreValue = false,
  handleToggleOnlyInThisStore,
  showPickers = false,
  disablePickers = false,
  banners = [],
  selectedBanner,
  setSelectedBanner,
  countries = [],
  selectedCountry,
  loading = false,
  setSelectedCountry,
  selectedTabParam,
}: MyAccountSearchBarProps) => {
  const styles = useStyle();
  const {t} = useTranslation();
  const {hasLoyalty} = useAuthentication();

  const [showScanModal, setShowScanModal] = useState(false);
  const [currentSearch, setCurrentSearch] = useState<string>(freeSearch);
  const [tabs, setTabs] = useState<{key: string; selected: boolean, visible: boolean}[]>([]);

  // Set tabs. Update when store is changed to display or not loyalty card search based on user's hasLoyalty flag
  useEffect(() => {
    setTabs([
      {
        key: 'CUSTOMER_DATA',
        selected: true,
        visible: true,
      },
      {
        key: 'LOYALTY_CARD',
        selected: false,
        visible: !!hasLoyalty,
      },
    ])
  }, [hasLoyalty]);

  const webcamRef = useRef<Webcam>(null);

  // Update selected tab when user lands on search results based on query param
  useEffect(() => {
    if (tabs.length > 0 && selectedTabParam) {
      setTabs(tabs.map(t => {return {...t, selected: selectedTabParam === t.key ? true : false}}));
    }
  }, [selectedTabParam, tabs]);

  // On tab change reset the freeSearch. Then update the selected tab key.
  // Also update the selected tab in parent component so the search type param is updated in the query
  const changeTab = useCallback((tabKey: string) => {
    onReset();
    setTabs(tabs.map(t => {return {...t, selected: tabKey === t.key ? true : false}}));
    onTabChange(tabKey);
  }, [onReset, onTabChange, tabs]);

  const selectedTabKey = useMemo(() => {
    return tabs.find(tab => tab.selected)?.key || 'CUSTOMER_DATA';
  }, [tabs]);

  const handleFreeSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onFreeSearchChange(e.target.value);
    },
    [onFreeSearchChange],
  );

  // Search button validations
  const canSubmit = useMemo(() => {
    switch (selectedTabKey) {
      case 'CUSTOMER_DATA':
        return freeSearch?.length >= minChars;
      case 'LOYALTY_CARD':
        return freeSearch?.length === LOYALTY_CARD_CHARACTERS && freeSearch.match(/^[0-9]+$/) != null;
    }
  }, [freeSearch, minChars, selectedTabKey]);

  // Hint displayed under the search bar. Defaults to none
  const searchHint = useMemo(() => {
    switch (selectedTabKey) {
      case 'LOYALTY_CARD':
        return t(`MyAccount.Search.Tabs.${selectedTabKey}_HINT`)
      default:
        return null;
    }
  }, [selectedTabKey, t]);

  const freeSearchInputProps = useMemo(
    () => ({
      multiline: false,
      endAdornment: freeSearch && (
        <InputAdornment position="end">
          <IconButton onClick={onReset}>
            <IconSVG icon="cross_circle" size={24} />
          </IconButton>
        </InputAdornment>
      ),
    }),
    [freeSearch, onReset],
  );

  const placeholder = useMemo(() => {
    return t(`MyAccount.Search.Tabs.${selectedTabKey}_PLACEHOLDER`);
  }, [t, selectedTabKey]);

  const renderTabs = useMemo(() => {
    return tabs.map((tab, i) => {
      if (!tab.visible) {
        return <></>;
      }

      return (
        <Box key={`${tab.key}-${i}`}>
          {tab.selected ? (
            <Box style={styles.tabSelected}>
              <Text variant="body" superHeavy color={Colors.White}>
                {t(`MyAccount.Search.Tabs.${tab.key}`)}
              </Text>
            </Box>
          ) : (
            <Box style={styles.tabSelectable} onClick={() => changeTab(tab.key)}>
              <Text variant="body">
                {t(`MyAccount.Search.Tabs.${tab.key}`)}
              </Text>
            </Box>
          )}
        </Box>
      );
    });
  }, [tabs, styles.tabSelected, styles.tabSelectable, t, changeTab]);

  const handleSubmit = useCallback(() => {
    setCurrentSearch(freeSearch);
    onSubmit();
  }, [freeSearch, onSubmit]);

  const bannersOptions = useMemo(() => {
    return banners
      ?.map(banner => {
        return {
          label: banner.name,
          value: banner.name,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [banners]);

  const countriesOptions = useMemo(() => {
    return countries
      ?.map(country => {
        return {
          label: country.name,
          value: country.name,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [countries]);

  /* Camera to scan the barcode */
  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    if (imageSrc) {
      codeReader
        .decodeFromImage(undefined, imageSrc)
        .then(result => {
          onFreeSearchChange(result.getText());
          setShowScanModal(false);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [onFreeSearchChange]);

  useEffect(() => {
    const interval = setInterval(capture, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [capture]);

  useScanDetection({
    onComplete: string => {
      onFreeSearchChange(string.toString());
    },
    minLength: 13, // EAN13
  });
  /* End Camera */

  return (
    <>
      <Box sx={styles.tabs}>
        <Box sx={styles.tabsButtons}>{renderTabs}</Box>
        {onlyInThisStoreVisible ? (
          <Box sx={styles.switchContainer}>
            <SwitchButton
              checked={onlyInThisStoreValue}
              onChange={handleToggleOnlyInThisStore}
            />
            <Text
              style={{marginTop: '4px'}}
              variant="body"
              color={Colors.GreyText}>
              {t('MyAccount.Search.onlyInThisStore')}
            </Text>
          </Box>
        ) : (
          <></>
        )}
        {showPickers && selectedTabKey === 'CUSTOMER_DATA' && setSelectedBanner && setSelectedCountry ? (
          <Box sx={styles.switchContainer}>
            <Select
              sx={styles.select}
              disabled={bannersOptions?.length === 0 || disablePickers}
              value={selectedBanner}
              options={bannersOptions || []}
              {...(!disablePickers && {
                onChange: e => setSelectedBanner(`${e.target.value}`),
              })}
            />
            <Select
              sx={styles.select}
              disabled={countriesOptions?.length === 0 || disablePickers}
              value={selectedCountry}
              options={countriesOptions || []}
              {...(!disablePickers && {
                onChange: e => setSelectedCountry(`${e.target.value}`),
              })}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={styles.searchBar}>
        <Box
          component="form"
          sx={styles.formContainerBasic}
          noValidate
          autoComplete="off"
          onSubmit={(event: FormEvent) => {
            event.preventDefault();
            if (canSubmit) {
              handleSubmit();
            }
          }}>
          <TextField
            autoComplete="off"
            type="text"
            inputProps={{
              spellCheck: 'false',
              autoCorrect: 'off',
            }}
            sx={styles.freeSearch}
            value={freeSearch}
            placeholder={placeholder}
            onChange={handleFreeSearchChange}
            InputProps={freeSearchInputProps}
            focused={false}
            fullWidth={true}
          />
          <SearchButton
            onClick={handleSubmit}
            disabled={!canSubmit}
            loading={loading}
          />
        </Box>
        {
          selectedTabKey === 'LOYALTY_CARD' ? (
            <Button
              variant="secondary"
              sx={styles.scanButton}
              onClick={() => setShowScanModal(true)}>
              <Text variant="body" superHeavy marginRight="8px">
                {t(`MyAccount.Search.scan`)}
              </Text>
              <Box marginTop="-4px">
                <IconSVG icon="camera" size={16} color={Colors.Black} />
              </Box>
            </Button>
          ) : (
            <></>
          )
        }
      </Box>
      {showScanModal && (
        <Dialog
          onClose={() => setShowScanModal(false)}
          closeIcon="close"
          open={showScanModal}
          dialogContent={
            <Box>
              <Webcam
                width={500}
                height={500}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                audio={false}
                videoConstraints={{
                  facingMode: 'environment',
                }}
                onUserMediaError={err => {
                  console.log(err);
                  setShowScanModal(false);
                }}
              />
            </Box>
          }
        />
      )}
      <Box sx={styles.hintBox}>
        <Text variant="tiny" color="#4D4D4D">{searchHint}</Text>
      </Box>
      {personas && personas.size < 1 ? (
        <Box sx={styles.noResults}>
          <Text
            variant="headline"
            superHeavy
            textAlign="center"
            color="#4D4D4D">
            {`${t('MyAccount.Search.noResults')}`}
            <br />
            {`“${currentSearch}“`}
          </Text>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(MyAccountSearchBar);
