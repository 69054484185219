import React, {useMemo, useRef, useState, useCallback, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {Box, Stack, useMediaQuery, useTheme, Button} from '@mui/material';
import {useQuery, useMutation} from '@tanstack/react-query';

import {Headline2} from '@/atoms/Headline2';
import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {LoadingPage} from '@/atoms/LoadingPage';
import {Modal} from '@/atoms/Modal';
import {TinyText} from '@/atoms/TinyText';
import {Text} from '@/atoms/Typography/Text';
import {useToast} from '@/hooks/toast';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useNavigation} from '@/navigation/useNavigation';
import {AppointmentHistory} from '@/organisms/AppointmentHistory';
import {Benefits} from '@/organisms/Benefits';
import {Feedback} from '@/organisms/Feedback';
import {LoyaltyBox} from '@/organisms/LoyaltyBox';
import {PersonalInformation} from '@/organisms/PersonalInformation';
import {PurchasesDetails} from '@/organisms/PurchasesDetails';
import {Quotes} from '@/organisms/Quotes';
import {ScriptBox} from '@/organisms/ScriptBox';
import {TaskHeaderDetails} from '@/organisms/TaskHeaderDetails';
import {useStyles} from '@/pages/Clienteling/SubPages/TaskDetails/styles';
import {
  useAppointmentsV2,
  GetAppointmentParamsV2,
  PatchAppointmentsParamsV2,
  DeleteAppointmentsParamsV2,
  PostAppointmentsParamsV2,
  PutDisassociateTaskAppointmentParamsV2,
  PostAppointmentHistoryParamsV2,
} from '@/store/appointments/v2';
import {useAuthentication} from '@/store/authentication';
import {GetBenefitsParams, useBenefit} from '@/store/benefit';
import {useCustomerInfo, PostLoyaltyTiersInfoBody} from '@/store/customersInfo';
import {
  GetFeedbackConfigurationParamsV2,
  useFeedbackV2,
  GetFeedbackParamsV2,
  GetFeedbackResponseV2,
  FEEDBACK_STATUS,
  PostFeedbackParamsV2,
} from '@/store/feedback/v2';
import {
  GetPersonalInformationCustomersParams,
  usePersonas,
} from '@/store/personas';
import {GetQuotationsParams, useQuotations} from '@/store/quotations';
import {useTasks} from '@/store/tasks';
import {
  GetLastPurchasesParams,
  GetScriptParams,
  GetTaskHeaderDetailsResponse,
  SingleTaskAssignee,
} from '@/store/tasks/types';
import {Base} from '@/templates/Base';
import {Colors} from '@/themes/variables';

const TaskDetails = () => {
  // Header ref
  const headerRef = useRef<HTMLDivElement>();
  const [headerHeight, setHeaderHeight] = useState<number>(60);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Task details',
    });
  }, []);

  useEffect(() => {
    const setHeaderHeightLocal = () => {
      if (headerRef?.current) {
        setHeaderHeight(headerRef?.current?.clientHeight);
      }
    };

    setHeaderHeightLocal();

    window.addEventListener('resize', setHeaderHeightLocal);
    const resizeObserver = new ResizeObserver(setHeaderHeightLocal);
    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', setHeaderHeightLocal);
    };
  }, [headerRef]);

  const styles = useStyles();
  const theme = useTheme();
  const {showErrorToast, showSuccessToast, showSuccessToastAssignTask} =
    useToast();
  const {
    history,
    goBack,
    goToPath,
    replacePath,
    goToPurchaseList,
    goToClientResults,
  } = useNavigation();
  const {getTranslationWithValue} = useBaseTranslation(
    'TaskDetails',
    'LoyaltyBox',
  );
  const {t} = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const {
    userStoreId,
    hasLoyalty,
    userCountry,
    appointmentType,
    isContactCenterAgent,
    isContactCenterSupervisor,
    userData,
  } = useAuthentication();

  const {getPersonalInformationCustomers} = usePersonas();
  const {getLastPurchases, getScript, getTaskHeaderDetails} = useTasks();
  const {getQuotations} = useQuotations();
  const {getBenefits} = useBenefit();
  const {getFeedbackConfiguration, getFeedbackData, postFeedback} =
    useFeedbackV2();

  const {
    getTaskAppointment,
    postTaskAppointment,
    putDisassociateTaskAppointment,
    patchTaskAppointment,
    deleteTaskAppointment,
    getAppointmentHistory,
  } = useAppointmentsV2();

  const {postCustomerinfoSubscribe, postLoyaltyTiersInfo} = useCustomerInfo();

  const isCC = useMemo(() => {
    return isContactCenterAgent || isContactCenterSupervisor;
  }, [isContactCenterAgent, isContactCenterSupervisor]);

  const showAppointments = useMemo(() => {
    return !!appointmentType || isCC;
  }, [appointmentType, isCC]);

  /**
   * @brief Task header state
   * @note This state is used to store task header state
   */
  const [taskHeaderState, setTaskHeaderState] = useState<
    GetTaskHeaderDetailsResponse | undefined
  >(undefined);

  /**
   * @brief Feedback state
   * @note This state is used to store feedback state
   */
  const [feedbackState, setFeedbackState] = useState<
    GetFeedbackResponseV2 | undefined
  >(undefined);

  /**
   * @brief Feedback changed state
   * @note This state is used to store feedback changed state
   */
  const [feedbackChangedState, setFeedbackChangedState] =
    useState<boolean>(false);

  /**
   * @brief Back modal state
   */
  const [showBackModal, setShowBackModal] = useState<boolean>(false);
  const [unregisterCallback, setUnregisterCallback] = useState<() => void>();

  /**
   * @brief Toggle back modal
   * @note This function is used to toggle back modal
   */
  const toggleBackModal = useCallback((value?: boolean) => {
    setShowBackModal(prevValue => {
      return typeof value !== 'undefined' ? value : !prevValue;
    });
  }, []);

  /**
   * @brief Handle feedback change
   * @note This function is used to handle feedback change
   */
  const handleFeedbackChange = useCallback(
    (key: keyof GetFeedbackResponseV2, value: any) => {
      setFeedbackState(prevValue => {
        return {
          ...prevValue!,
          [key]: value,
        };
      });
    },
    [],
  );

  /**
   * @brief Change local assignee
   * @note This function is used to change local assignee
   */
  const changeLocalAssignee = useCallback(
    (newAssignee?: SingleTaskAssignee | null) => {
      setTaskHeaderState(prevState => {
        return {
          ...prevState!,
          assignee: newAssignee,
        };
      });
    },
    [],
  );

  /**
   * @brief Get task id from URL
   */
  const {taskId} = useParams<{taskId: string}>();

  /**
   * @brief Get task header details
   * @note This query is used to get task header details
   */
  const {
    data: TaskHeaderDetailsData,
    refetch: RefetchTaskHeaderDetails,
    isError: TaskHeaderDetailsDataError,
  } = useQuery(
    ['getTaskHeaderDetails', taskId],
    () => getTaskHeaderDetails({taskId}),
    {
      enabled: !!taskId,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: data => {
        setTaskHeaderState(data);
      },
    },
  );

  /**
   * @brief Assign refetch
   * @note This function is used to refetch task header details
   */
  const onAssignRefetch = useCallback(async () => {
    await RefetchTaskHeaderDetails();
  }, [RefetchTaskHeaderDetails]);

  /**
   * @brief Loyalty tiers info payload
   * @note This payload is used to get loyalty tiers info
   */
  const loyaltyTiersInfoPayload = useMemo<PostLoyaltyTiersInfoBody>(() => {
    return {
      email: TaskHeaderDetailsData?.email ?? '',
      bannerSearch: TaskHeaderDetailsData?.banner ?? '',
      countrySearch: TaskHeaderDetailsData?.country ?? '',
      ...(!!TaskHeaderDetailsData?.customerId &&
        TaskHeaderDetailsData?.customerId !== '' && {
          customerId: TaskHeaderDetailsData?.customerId,
          personaChannel: 'RETAIL',
        }),
    };
  }, [
    TaskHeaderDetailsData?.banner,
    TaskHeaderDetailsData?.country,
    TaskHeaderDetailsData?.customerId,
    TaskHeaderDetailsData?.email,
  ]);

  /**
   * @brief Get personal information customers
   * @note This query is used to get personal information customers
   */
  const {
    data: LoyaltyTiersInfoData,
    refetch: LoyaltyTiersInfoRefetch,
    isFetching: LoyaltyTiersInfoLoading,
  } = useQuery(
    ['postLoyaltyTiersInfo', loyaltyTiersInfoPayload],
    () => postLoyaltyTiersInfo(loyaltyTiersInfoPayload),
    {
      enabled: !!TaskHeaderDetailsData && hasLoyalty,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  /**
   * @brief Mutation to handle post customer info subscribe
   */
  const {
    mutate: PostCustomerinfoSubscribe,
    isLoading: PostCustomerinfoSubscribeLoading,
  } = useMutation(postCustomerinfoSubscribe, {
    onSuccess: async () => {
      showSuccessToast(getTranslationWithValue(1, 'toasts.success'));
      await LoyaltyTiersInfoRefetch();
    },
    onError: async () => {
      showErrorToast(getTranslationWithValue(1, 'toasts.error'));
    },
  });

  /**
   * @brief Personal information payload
   * @note This payload is used to get personal information customers
   */
  const personalInformationPayload =
    useMemo<GetPersonalInformationCustomersParams>(() => {
      return {
        email: TaskHeaderDetailsData?.email ?? '',
        bannerSearch: TaskHeaderDetailsData?.banner ?? '',
        countrySearch: TaskHeaderDetailsData?.country ?? '',
        ...(!!TaskHeaderDetailsData?.customerId &&
          TaskHeaderDetailsData?.customerId !== '' && {
            customerId: TaskHeaderDetailsData?.customerId,
            personaChannel: 'RETAIL',
            singleCustomerId: true,
          }),
      };
    }, [TaskHeaderDetailsData]);

  /**
   * @brief Get personal information customers
   * @note This query is used to get personal information customers
   */
  const {
    data: CustomersData,
    isLoading: CustomersLoading,
    isError: CustomersError,
  } = useQuery(
    ['getPersonalInformationCustomers', personalInformationPayload],
    async () => {
      /**
       * @note If customerId is not found, then we will call the API without customerId
       */
      try {
        return await getPersonalInformationCustomers(
          personalInformationPayload,
        );
      } catch (err) {
        return await getPersonalInformationCustomers({
          ...personalInformationPayload,
          customerId: undefined,
          personaChannel: undefined,
          singleCustomerId: false,
        });
      }
    },
    {
      enabled: !!TaskHeaderDetailsData,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  /**
   * @brief Last purchases payload
   * @note This payload is used to get last purchases
   */
  const lastPurchasesPayload = useMemo<GetLastPurchasesParams>(() => {
    return {
      storeId: TaskHeaderDetailsData?.storeId ?? '',
      taskId,
      banner: TaskHeaderDetailsData?.banner ?? '',
      country: TaskHeaderDetailsData?.country ?? '',
    };
  }, [
    TaskHeaderDetailsData?.banner,
    TaskHeaderDetailsData?.country,
    TaskHeaderDetailsData?.storeId,
    taskId,
  ]);

  /**
   * @brief Get last purchases
   * @note This query is used to get last purchases
   */
  const {data: LastPurchasesData} = useQuery(
    ['getLastPurchases', lastPurchasesPayload],
    () => getLastPurchases(lastPurchasesPayload),
    {
      enabled: !!TaskHeaderDetailsData,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  /**
   * @brief Quotations payload
   * @note This payload is used to get quotations
   */
  const quotationsPayload = useMemo<GetQuotationsParams>(() => {
    return {
      email: TaskHeaderDetailsData?.email ?? '',
      bannerSearch: TaskHeaderDetailsData?.banner ?? '',
      countrySearch: TaskHeaderDetailsData?.country ?? '',
    };
  }, [
    TaskHeaderDetailsData?.banner,
    TaskHeaderDetailsData?.country,
    TaskHeaderDetailsData?.email,
  ]);

  /**
   * @brief Get quotations
   * @note This query is used to get quotations
   */
  const {data: QuotationsData} = useQuery(
    ['getQuotations', quotationsPayload],
    () => getQuotations(quotationsPayload),
    {
      enabled: !!TaskHeaderDetailsData,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  /**
   * @brief Benefits payload
   * @note This payload is used to get benefits
   */
  const benefitsPayload = useMemo<GetBenefitsParams>(() => {
    return {
      email: TaskHeaderDetailsData?.email ?? '',
      bannerSearch: TaskHeaderDetailsData?.banner ?? '',
      countrySearch: TaskHeaderDetailsData?.country ?? '',
    };
  }, [
    TaskHeaderDetailsData?.banner,
    TaskHeaderDetailsData?.country,
    TaskHeaderDetailsData?.email,
  ]);

  /**
   * @brief Get benefits
   * @note This query is used to get benefits
   */
  const {data: BenefitsData} = useQuery(
    ['getBenefits', benefitsPayload],
    () => getBenefits(benefitsPayload),
    {
      enabled: !!TaskHeaderDetailsData,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  /**
   * @brief Script payload
   * @note This payload is used to get script
   */
  const scriptPayload = useMemo<GetScriptParams>(() => {
    return {
      storeId: userStoreId?.toString() ?? '',
      taskId,
    };
  }, [taskId, userStoreId]);

  /**
   * @brief Get script
   * @note This query is used to get script
   */
  const {data: ScriptData} = useQuery(
    ['getScript', scriptPayload],
    () => getScript(scriptPayload),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  /**
   * @brief Feedback configuration payload
   * @note This payload is used to get feedback configuration
   */
  const feedbackConfigurationPayload =
    useMemo<GetFeedbackConfigurationParamsV2>(() => {
      return {
        taskCategory: TaskHeaderDetailsData?.taskCategory ?? '',
      };
    }, [TaskHeaderDetailsData?.taskCategory]);

  /**
   * @brief Get feedback configuration
   * @note This query is used to get feedback configuration
   */
  const {data: FeedbackConfigurationData} = useQuery(
    ['getFeedbackConfiguration', feedbackConfigurationPayload],
    () => getFeedbackConfiguration(feedbackConfigurationPayload),
    {
      enabled: !!TaskHeaderDetailsData,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  /**
   * @brief Feedback data payload
   * @note This payload is used to get feedback data
   */
  const feedbackDataPayload = useMemo<GetFeedbackParamsV2>(() => {
    return {
      storeId: userStoreId?.toString() ?? '',
      taskId,
    };
  }, [taskId, userStoreId]);

  /**
   * @brief Get feedback data
   * @note This query is used to get feedback data
   */
  const {data: FeedbackData, refetch: RefetchFeedbackData} = useQuery(
    ['getFeedbackData', feedbackDataPayload],
    () => getFeedbackData(feedbackDataPayload),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: data => {
        setFeedbackState(data);
      },
    },
  );

  /**
   * @brief Mutation to handle post feedback
   * @note This mutation is used to handle post feedback
   */
  const {mutate: PostFeedback, isLoading: PostFeedbackLoading} = useMutation(
    postFeedback,
    {
      onSuccess: async () => {
        showSuccessToastAssignTask(
          getTranslationWithValue(0, 'postFeedbackSuccess'),
        );
        if (unregisterCallback) {
          unregisterCallback();
          return;
        }
        await RefetchTaskHeaderDetails();
        await RefetchFeedbackData();
      },
      onError: () => {
        showErrorToast(getTranslationWithValue(0, 'postFeedbackError'));
      },
    },
  );

  /**
   * @brief Handle post feedback
   * @note This function is used to handle post feedback
   */
  const handlePostFeedback = useCallback(() => {
    const feedbackPayload: PostFeedbackParamsV2 = {
      taskId,
      storeId: userStoreId?.toString() ?? '',
      note: feedbackState?.note ?? undefined,
      assigneeId: taskHeaderState?.assignee?.id ?? undefined,
      status: feedbackState?.status!,
      callBackDateTime: feedbackState?.callBackDateTime ?? undefined,
      refusalReason: feedbackState?.refusalReason ?? undefined,
    };

    PostFeedback(feedbackPayload);
  }, [
    PostFeedback,
    feedbackState?.callBackDateTime,
    feedbackState?.note,
    feedbackState?.refusalReason,
    feedbackState?.status,
    taskHeaderState?.assignee?.id,
    taskId,
    userStoreId,
  ]);

  // Start section

  /**
   * @brief Appointment payload
   * @note This payload is used to get appointment
   */
  const getAppointmentPayload = useMemo<GetAppointmentParamsV2>(() => {
    return {
      storeId: userStoreId?.toString() ?? '',
      taskId,
      banner: TaskHeaderDetailsData?.banner ?? '',
      country: TaskHeaderDetailsData?.country ?? '',
    };
  }, [
    TaskHeaderDetailsData?.banner,
    TaskHeaderDetailsData?.country,
    taskId,
    userStoreId,
  ]);

  /**
   * @brief Get appointment
   * @note This query is used to get appointment
   */
  const {data: AppointmentData, refetch: RefetchAppointmentData} = useQuery(
    ['getTaskAppointment', getAppointmentPayload],
    () => getTaskAppointment(getAppointmentPayload),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!TaskHeaderDetailsData,
    },
  );

  /**
   * @brief AppointmentHistoryPayload payload
   * @note This payload is used to get appointment history
   */
  const getAppointmentHistoryPayload =
    useMemo<PostAppointmentHistoryParamsV2>(() => {
      return {
        storeId: userStoreId?.toString() ?? '',
        taskId,
        email: TaskHeaderDetailsData?.email ?? '',
        bannerSearch: TaskHeaderDetailsData?.banner ?? '',
        countrySearch: TaskHeaderDetailsData?.country ?? '',
      };
    }, [
      TaskHeaderDetailsData?.banner,
      TaskHeaderDetailsData?.country,
      TaskHeaderDetailsData?.email,
      taskId,
      userStoreId,
    ]);

  const {
    data: AppointmentHistoryData,
    isFetching: AppointmentHistoryLoading,
    refetch: AppointmentHistoryRefetch,
  } = useQuery({
    queryKey: [
      'getAppointmentHistory',
      getAppointmentHistoryPayload?.email,
      getAppointmentHistoryPayload?.bannerSearch,
      getAppointmentHistoryPayload?.countrySearch,
      userStoreId,
      taskId,
    ],
    queryFn: () => getAppointmentHistory(getAppointmentHistoryPayload),
    enabled: !!TaskHeaderDetailsData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    retry: false,
  });

  /**
   * @brief Mutation to handle patch appointments
   * @note This mutation is used to handle patch appointments
   */
  const {mutate: PatchTaskAppointment, isLoading: PatchTaskAppointmentLoading} =
    useMutation({
      mutationKey: ['patchTaskAppointment'],
      mutationFn: (variables: PatchAppointmentsParamsV2) =>
        patchTaskAppointment(variables),
      onSuccess: () => {
        showSuccessToastAssignTask(
          getTranslationWithValue(0, 'appointmentSuccess'),
        );
      },
      onError: () => {
        showErrorToast(getTranslationWithValue(0, 'appointmentError'));
      },
      onSettled: async () => {
        await RefetchAppointmentData();
      },
    });

  /**
   * @brief Handle patch task appointment
   * @note This function is used to handle patch task appointment
   */
  const handlePatchTaskAppointment = useCallback(
    (variables: PatchAppointmentsParamsV2) => {
      PatchTaskAppointment(variables);
    },
    [PatchTaskAppointment],
  );

  /**
   * @brief Mutation to handle delete appointments
   * @note This mutation is used to handle delete appointments
   */
  const {
    mutate: DeleteTaskAppointment,
    isLoading: DeleteTaskAppointmentLoading,
  } = useMutation({
    mutationKey: ['deleteTaskAppointment'],
    mutationFn: (variables: DeleteAppointmentsParamsV2) =>
      deleteTaskAppointment(variables),
    onSuccess: () => {
      showSuccessToastAssignTask(
        getTranslationWithValue(0, 'appointmentSuccess'),
      );
    },
    onError: () => {
      showErrorToast(getTranslationWithValue(0, 'appointmentError'));
    },
    onSettled: async () => {
      await RefetchAppointmentData();
    },
  });

  /**
   * @brief Handle delete task appointment
   * @note This function is used to handle delete task appointment
   */
  const handleDeleteTaskAppointment = useCallback(
    (variables: DeleteAppointmentsParamsV2) => {
      DeleteTaskAppointment(variables);
    },
    [DeleteTaskAppointment],
  );

  /**
   * @brief Mutation to handle post appointments
   * @note This mutation is used to handle post appointments
   */
  const {
    mutate: PostTaskAppointment,
    isLoading: PostTaskAppointmentLoading,
    mutateAsync: PostTaskAppointmentAsync,
  } = useMutation({
    mutationKey: ['postTaskAppointment'],
    mutationFn: (variables: PostAppointmentsParamsV2) =>
      postTaskAppointment(variables),
    onSuccess: () => {
      showSuccessToastAssignTask(
        getTranslationWithValue(0, 'appointmentSuccess'),
      );
      handleFeedbackChange('status', FEEDBACK_STATUS.CONTACTED_POSITIVE);
    },
    onError: () => {
      showErrorToast(getTranslationWithValue(0, 'appointmentError'));
    },
    onSettled: async () => {
      const refetchAppointmentDataPromise = RefetchAppointmentData();
      const appointmentHistoryRefetchPromise = AppointmentHistoryRefetch();
      await Promise.all([
        refetchAppointmentDataPromise,
        appointmentHistoryRefetchPromise,
      ]);
    },
  });

  /**
   * @brief Handle create task appointment
   * @note This function is used to handle create task appointment
   */
  const handleCreateTaskAppointment = useCallback(
    (variables: PostAppointmentsParamsV2) => {
      PostTaskAppointment(variables);
    },
    [PostTaskAppointment],
  );

  /**
   * @brief Mutation to handle disassociate task appointment
   * @note This mutation is used to handle disassociate task appointment
   */
  const {
    mutate: DisassociateTaskAppointment,
    isLoading: DisassociateTaskAppointmentLoading,
    mutateAsync: DisassociateTaskAppointmentAsync,
  } = useMutation({
    mutationKey: ['disassociateTaskAppointment'],
    mutationFn: (variables: PutDisassociateTaskAppointmentParamsV2) =>
      putDisassociateTaskAppointment(variables),
    onSuccess: () => {
      showSuccessToastAssignTask(
        getTranslationWithValue(0, 'appointmentSuccess'),
      );
    },
    onError: () => {
      showErrorToast(getTranslationWithValue(0, 'appointmentError'));
    },
    onSettled: async () => {
      const refetchAppointmentDataPromise = RefetchAppointmentData();
      const appointmentHistoryRefetchPromise = AppointmentHistoryRefetch();
      await Promise.all([
        refetchAppointmentDataPromise,
        appointmentHistoryRefetchPromise,
      ]);
    },
  });

  /**
   * @brief Handle disassociate task appointment
   * @note This function is used to handle disassociate task appointment
   */
  const handleDisassociateTaskAppointment = useCallback(
    (variables: PutDisassociateTaskAppointmentParamsV2) => {
      DisassociateTaskAppointment(variables);
    },
    [DisassociateTaskAppointment],
  );

  const handleDisassociateAndAssociateTaskAppointment = useCallback(
    async (variables: PostAppointmentsParamsV2) => {
      try {
        await DisassociateTaskAppointmentAsync({
          storeId: variables?.storeId,
          taskId: variables?.taskId,
          banner: variables?.banner,
          country: variables?.country,
        });
        await PostTaskAppointmentAsync(variables);
        const refetchAppointmentDataPromise = RefetchAppointmentData();
        const appointmentHistoryRefetchPromise = AppointmentHistoryRefetch();
        await Promise.all([
          refetchAppointmentDataPromise,
          appointmentHistoryRefetchPromise,
        ]);
        showSuccessToastAssignTask(
          getTranslationWithValue(0, 'appointmentSuccess'),
        );
      } catch (err) {
        showErrorToast(getTranslationWithValue(0, 'appointmentError'));
      }
    },
    [
      AppointmentHistoryRefetch,
      DisassociateTaskAppointmentAsync,
      PostTaskAppointmentAsync,
      RefetchAppointmentData,
      getTranslationWithValue,
      showErrorToast,
      showSuccessToastAssignTask,
    ],
  );

  // end section

  /**
   * @brief Feedback changed state
   * @note This effect is used to check if feedback state has changed
   */
  useEffect(() => {
    if (!feedbackState || !FeedbackData || !TaskHeaderDetailsData) {
      return;
    }

    const assigneeChanged =
      taskHeaderState?.assignee?.id !== TaskHeaderDetailsData?.assignee?.id;
    const statusChanged = feedbackState?.status !== FeedbackData?.status;
    const noteChanged = feedbackState?.note !== FeedbackData?.note;
    const refusalReasonChanged =
      feedbackState?.refusalReason !== FeedbackData?.refusalReason;
    const callbackDateTimeChanged =
      feedbackState?.callBackDateTime !== FeedbackData?.callBackDateTime;

    setFeedbackChangedState(
      assigneeChanged ||
        statusChanged ||
        noteChanged ||
        refusalReasonChanged ||
        callbackDateTimeChanged,
    );
  }, [
    FeedbackData,
    TaskHeaderDetailsData,
    TaskHeaderDetailsData?.assignee?.id,
    feedbackState,
    taskHeaderState?.assignee?.id,
  ]);

  /**
   * @brief Change page listener
   * @note This listener is used to listen when page is changed and there uncommitted changes
   */
  useEffect(() => {
    const unblock = history?.block((location, action) => {
      if (feedbackChangedState) {
        setUnregisterCallback(() => {
          switch (action) {
            case 'PUSH':
              return () => goToPath(location?.pathname, location?.search);
            case 'POP':
              return () => goBack();
            default:
              return () => replacePath(location?.pathname, location?.search);
          }
        });
        setFeedbackChangedState(false);
        toggleBackModal(true);
        return false;
      }
      unblock();
    });
    return () => {
      unblock();
    };
  }, [
    feedbackChangedState,
    goBack,
    goToPath,
    history,
    replacePath,
    toggleBackModal,
  ]);

  // Root ref for the container scrollable column
  const rootRef = useRef<HTMLDivElement>();
  // Array of refs for each anchor card
  const itemsRef = useRef<{[anchorIndex: string]: HTMLDivElement}>({});
  // Current index of anchor card visible on screen
  const [currentViewIndex, setCurrentViewIndex] = useState<string>('00');

  // Execute a smooth scroll targeting an anchor card
  const executeScroll = useCallback((i: string) => {
    itemsRef.current[i].scrollIntoView({behavior: 'smooth', block: 'start'});
  }, []);

  const anchors = useMemo(() => {
    return [
      {
        title: getTranslationWithValue(0, 'anchors.client'),
        list: [
          getTranslationWithValue(0, 'anchors.personalInformation'),
          getTranslationWithValue(0, 'anchors.purchasesDetails'),
          ...(hasLoyalty
            ? [getTranslationWithValue(0, 'anchors.loyalty')]
            : []),
          getTranslationWithValue(0, 'anchors.benefits'),
          // t('MyAccount.Anchors.linkedTasks'),
          ...(showAppointments
            ? [getTranslationWithValue(0, 'anchors.appointments')]
            : []),
          getTranslationWithValue(0, 'anchors.quotes'),
          // t('MyAccount.Anchors.prescriptions'),
          // t('MyAccount.Anchors.subscriptions'),
        ],
      },
      {
        title: getTranslationWithValue(0, 'anchors.scripts'),
        list: [getTranslationWithValue(0, 'anchors.scripts')],
      },
    ];
  }, [getTranslationWithValue, showAppointments, hasLoyalty]);

  const indexes = useMemo(() => {
    return {
      personalInformation: '00',
      purchasesDetails: '01',
      loyalty: '02',
      benefits: hasLoyalty ? '03' : '02',
      appointments: hasLoyalty && showAppointments ? '04' : '03',
      quotes: hasLoyalty
        ? showAppointments
          ? '05'
          : '04'
        : showAppointments
        ? '04'
        : '03',
      scripts: '10',
    };
  }, [hasLoyalty, showAppointments]);

  // Render each anchor link
  const renderAnchors = useMemo(() => {
    return anchors.map((anchor, listIndex) => {
      return (
        <Box key={`${anchor.title}_${listIndex}`}>
          <Text variant="caption" superHeavy uppercase>
            {anchor.title}
          </Text>
          <Box sx={styles.anchors}>
            {anchor.list.map((el, anchorIndex) => {
              return (
                <Box
                  sx={styles.anchorBox}
                  marginBottom="16px"
                  key={`${el}_${listIndex}_${anchorIndex}`}>
                  <TinyText
                    superHeavy
                    sx={{textDecoration: 'none', cursor: 'pointer'}}
                    onClick={() => executeScroll(`${listIndex}${anchorIndex}`)}
                    color={
                      currentViewIndex === `${listIndex}${anchorIndex}`
                        ? Colors.Black
                        : Colors.GreyDark
                    }>
                    {el}
                  </TinyText>
                </Box>
              );
            })}
          </Box>
        </Box>
      );
    });
  }, [
    anchors,
    currentViewIndex,
    executeScroll,
    styles.anchorBox,
    styles.anchors,
  ]);

  useEffect(() => {
    const scrollContainer = document.getElementById('scrollable-column');
    const lastBox = itemsRef.current['10'];

    const calculateMargin = () => {
      if (!lastBox) return;

      const containerHeight = scrollContainer!.clientHeight;
      const lastBoxHeight = lastBox.clientHeight;

      const marginBottom = containerHeight - lastBoxHeight;
      lastBox.style.marginBottom = `${marginBottom}px`;
    };

    calculateMargin();

    const resizeObserver = new ResizeObserver(calculateMargin);
    Object.keys(itemsRef.current).forEach(key =>
      resizeObserver.observe(itemsRef.current[key]),
    );

    window.addEventListener('resize', calculateMargin);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', calculateMargin);
    };
  }, [itemsRef]);

  useEffect(() => {
    const container = document.getElementById('scrollable-column');
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleScroll = () => {
    const scrollContainer = document.getElementById('scrollable-column');
    const containerTop = scrollContainer!.getBoundingClientRect().top;

    let closestIndex = '';
    let closestDistance = Infinity;

    Object.keys(itemsRef.current).forEach(key => {
      const box = itemsRef.current[key];
      const boxTop = box.getBoundingClientRect().top - containerTop!; // Adjusted for scrollable column top
      const distance = Math.abs(boxTop);

      if (distance < closestDistance) {
        closestDistance = distance;
        closestIndex = key;
      }
    });

    setCurrentViewIndex(closestIndex!);
  };

  return (
    <Base title={getTranslationWithValue(0, 'pageTitle')}>
      {TaskHeaderDetailsDataError && (
        <Stack
          position={'absolute'}
          top={0}
          left={0}
          right={0}
          bottom={0}
          justifyContent={'center'}
          alignItems={'center'}
          gap={1.6}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1000,
            backdropFilter: 'blur(4px)',
          }}>
          <Headline2 medium>{getTranslationWithValue(0, 'error')}</Headline2>
          <Button variant={'basePrimary'} onClick={goBack}>
            {getTranslationWithValue(0, 'back')}
          </Button>
        </Stack>
      )}
      <>
        {(PostTaskAppointmentLoading ||
          DisassociateTaskAppointmentLoading ||
          DeleteTaskAppointmentLoading ||
          PatchTaskAppointmentLoading ||
          PostFeedbackLoading ||
          PostCustomerinfoSubscribeLoading) && (
          <LoadingPage invisible={false} />
        )}
        {/* Header */}
        <Stack direction="column" flex={1} gap={1.6} overflow="hidden">
          <Box>
            <Box sx={styles.headingGrid} ref={headerRef}>
              <Link
                linkVariant="dark"
                textVariant="body"
                href="#"
                handleOnClick={goBack}
                iconStart={
                  <IconSVG icon="arrow_left_2" size={16} color="#000" />
                }>
                {getTranslationWithValue(0, 'back')}
              </Link>
              <TaskHeaderDetails
                taskHeaderData={taskHeaderState}
                onAssignRefetch={onAssignRefetch}
                changeLocalAssignee={changeLocalAssignee}
                feedbackChanged={feedbackChangedState}
              />
            </Box>
          </Box>
          {/* Grid */}
          <Box sx={styles.grid(headerHeight)}>
            {/* Anchors - 1st column */}
            <Box>{renderAnchors}</Box>
            {/* Content / Scrollable column - 2nd column */}
            <Box
              id="scrollable-column"
              ref={rootRef}
              sx={{overflowY: 'scroll', scrollbarWidth: 'none'}}>
              <Stack direction="column" gap={1.6}>
                {/* On tablet portrait show feedback in this column as first element */}
                {!isDesktop ? (
                  <Box>
                    <Feedback
                      customerError={CustomersError}
                      customer={
                        CustomersData ? CustomersData?.customers[0] : undefined
                      }
                      feedbackConfiguration={FeedbackConfigurationData}
                      feedbackChanged={feedbackChangedState}
                      feedbackState={feedbackState}
                      handleFeedbackChange={handleFeedbackChange}
                      onFeedbackSave={() => handlePostFeedback()}
                      appointment={AppointmentData}
                      taskHeaderData={TaskHeaderDetailsData}
                      taskId={taskId}
                      handleCreateTaskAppointment={handleCreateTaskAppointment}
                      handleDisassociateTaskAppointment={
                        handleDisassociateTaskAppointment
                      }
                      handleDeleteAppointment={handleDeleteTaskAppointment}
                      handleUpdateTaskAppointment={handlePatchTaskAppointment}
                    />
                  </Box>
                ) : (
                  <></>
                )}
                <Box
                  ref={(el: HTMLDivElement) =>
                    (itemsRef.current[indexes.personalInformation] = el)
                  }>
                  <PersonalInformation
                    viewAllProfiles={!isCC}
                    error={CustomersError}
                    customersResponse={CustomersData?.customers ?? []}
                    loading={CustomersLoading}
                    viewAllProfileCB={() =>
                      goToClientResults(
                        TaskHeaderDetailsData?.email!,
                        TaskHeaderDetailsData?.banner!,
                        TaskHeaderDetailsData?.country!,
                      )
                    }
                  />
                </Box>
                <HeadlineText heavy marginTop={2.4}>
                  {t('MyAccount.informationFromAllProfiles')}
                </HeadlineText>
                <Box
                  ref={(el: HTMLDivElement) =>
                    (itemsRef.current[indexes.purchasesDetails] = el)
                  }>
                  <PurchasesDetails
                    country={userCountry!}
                    response={LastPurchasesData}
                    viewPurchaseHistoryCB={() =>
                      goToPurchaseList(
                        `${TaskHeaderDetailsData?.firstName} ${TaskHeaderDetailsData?.lastName}`,
                        TaskHeaderDetailsData?.email!,
                        TaskHeaderDetailsData?.banner!,
                        TaskHeaderDetailsData?.country!,
                      )
                    }
                  />
                </Box>
                {hasLoyalty && (
                  <Box
                    ref={(el: HTMLDivElement) =>
                      (itemsRef.current[indexes.loyalty] = el)
                    }>
                    <LoyaltyBox
                      origin={'task'}
                      {...(CustomersData?.customers && {
                        customer: CustomersData?.customers[0],
                      })}
                      loading={LoyaltyTiersInfoLoading}
                      response={LoyaltyTiersInfoData}
                      subscribeCB={PostCustomerinfoSubscribe}
                      bannerSearch={TaskHeaderDetailsData?.banner!}
                      countrySearch={TaskHeaderDetailsData?.country!}
                    />
                  </Box>
                )}
                <Box
                  ref={(el: HTMLDivElement) =>
                    (itemsRef.current[indexes.benefits] = el)
                  }>
                  <Benefits response={BenefitsData} />
                </Box>
                {showAppointments && (
                  <Box
                    ref={(el: HTMLDivElement) =>
                      (itemsRef.current[indexes.appointments] = el)
                    }>
                    <AppointmentHistory
                      taskId={taskId}
                      readOnly={isCC}
                      taskIsEditable={TaskHeaderDetailsData?.taskIsEditable}
                      loading={AppointmentHistoryLoading}
                      response={AppointmentHistoryData}
                      {...(CustomersData?.customers && {
                        customer: CustomersData?.customers[0],
                      })}
                      handleCreateTaskAppointment={handleCreateTaskAppointment}
                      taskHeaderData={TaskHeaderDetailsData}
                      handleDisassociateAndAssociateTaskAppointment={
                        handleDisassociateAndAssociateTaskAppointment
                      }
                      currentAppointment={AppointmentData}
                      currentStoreId={userData?.currentStore?.storeId}
                    />
                  </Box>
                )}
                <Box
                  ref={(el: HTMLDivElement) =>
                    (itemsRef.current[indexes.quotes] = el)
                  }>
                  <Quotes response={QuotationsData} />
                </Box>
                <Box
                  ref={(el: HTMLDivElement) =>
                    (itemsRef.current[indexes.scripts] = el)
                  }>
                  <ScriptBox response={ScriptData} />
                </Box>
              </Stack>
            </Box>
            {isDesktop && (
              <Box className="feedbackRight">
                <Feedback
                  customer={
                    CustomersData ? CustomersData?.customers[0] : undefined
                  }
                  feedbackConfiguration={FeedbackConfigurationData}
                  feedbackChanged={feedbackChangedState}
                  feedbackState={feedbackState}
                  handleFeedbackChange={handleFeedbackChange}
                  onFeedbackSave={() => handlePostFeedback()}
                  appointment={AppointmentData}
                  taskHeaderData={TaskHeaderDetailsData}
                  taskId={taskId}
                  handleCreateTaskAppointment={handleCreateTaskAppointment}
                  handleDisassociateTaskAppointment={
                    handleDisassociateTaskAppointment
                  }
                  handleDeleteAppointment={handleDeleteTaskAppointment}
                  handleUpdateTaskAppointment={handlePatchTaskAppointment}
                />
              </Box>
            )}
          </Box>
        </Stack>
        {showBackModal && (
          <>
            <Modal
              open={showBackModal}
              maxWidth={38}
              onClose={() => {
                setFeedbackChangedState(true);
                toggleBackModal(false);
              }}
              dialogContent={
                <Stack
                  marginTop={3.2}
                  direction="column"
                  gap={3.2}
                  width="100%"
                  textAlign="center">
                  {getTranslationWithValue(0, 'confirmModal.title')}
                  <Stack
                    display="grid"
                    gridTemplateColumns="repeat(2, 1fr)"
                    gap={2.4}>
                    <Button
                      variant="baseSecondary"
                      fullWidth
                      onClick={() => {
                        toggleBackModal(false);
                        if (unregisterCallback) {
                          unregisterCallback();
                        }
                      }}>
                      {getTranslationWithValue(0, 'confirmModal.cta.cancel')}
                    </Button>
                    <Button
                      variant="basePrimary"
                      fullWidth
                      onClick={() => {
                        toggleBackModal(false);
                        handlePostFeedback();
                      }}>
                      {getTranslationWithValue(0, 'confirmModal.cta.confirm')}
                    </Button>
                  </Stack>
                </Stack>
              }
            />
          </>
        )}
      </>
    </Base>
  );
};

export default React.memo(TaskDetails);
