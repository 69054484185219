import React, {useState, useMemo, useRef, useCallback} from 'react';
import {BsChevronRight} from 'react-icons/bs';

import {Box, Skeleton, Stack} from '@mui/material';
import dayjs from 'dayjs';

import {Badge} from '@/atoms/Badge';
import {BodyText} from '@/atoms/BodyText';
import {Card} from '@/atoms/Card';
import {HeadlineText} from '@/atoms/HeadlineText';
import {TinyText} from '@/atoms/TinyText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {CustomAccordion} from '@/molecules/CustomAccordion';
import {useNavigation} from '@/navigation/useNavigation';
import {useStyles} from '@/organisms/LinkedTasks/styles';
import {useAuthentication} from '@/store/authentication';
import {PostLinkedTasksResponse} from '@/store/tasks/types';

export type LinkedTasksProps = {
  response?: PostLinkedTasksResponse;
  loading: boolean;
};

const BASE_TRANSLATION = 'LinkedTasks';

const LinkedTasks = ({response, loading}: LinkedTasksProps) => {
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  /**
   * Check if the accordion should be disabled
   */
  const disableClick = useMemo(() => {
    return typeof response === 'undefined' || loading;
  }, [loading, response]);

  const renderContent = useMemo(() => {
    if (disableClick) {
      return <Skeleton height="80px" animation="wave" variant="rounded" />;
    }

    if (!isOpen) return <Box />;

    return <LinkedTasksList response={response} />;
  }, [disableClick, isOpen, response]);

  return (
    <Card>
      <CustomAccordion
        bottomContent
        labelAlign={'center'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disableClick={disableClick}
        hideAccordionLabel={disableClick}
        accordionTitle={
          <HeadlineText superHeavy>
            {getTranslationWithValue(0, 'header')}
          </HeadlineText>
        }
        expandedContent={() => renderContent}
      />
    </Card>
  );
};

export default React.memo(LinkedTasks);

type LinkedTasksListProps = Pick<LinkedTasksProps, 'response'>;

const LinkedTasksList = React.memo(({response}: LinkedTasksListProps) => {
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
  const {linkedTasksContainer} = useStyles();
  const {goToTask, goToTaskMyAccount} = useNavigation();

  const {isContactCenterSupervisor, isContactCenterAgent} = useAuthentication();

  const taskListContainerRef = useRef<HTMLDivElement | null>(null);
  const tasksRefs = useRef<Array<HTMLDivElement>>([]);

  const isCC = useMemo(() => {
    return isContactCenterAgent || isContactCenterSupervisor;
  }, [isContactCenterAgent, isContactCenterSupervisor]);

  const handleTaskAction = useCallback(
    (taskId: string) => {
      if (!isCC) {
        goToTask(taskId);
      } else {
        goToTaskMyAccount(taskId);
      }
    },
    [goToTask, goToTaskMyAccount, isCC],
  );

  if (response?.length === 0) {
    return (
      <Stack direction="column" alignItems="center">
        <BodyText>{getTranslationWithValue(0, 'emptyState')}</BodyText>
      </Stack>
    );
  }

  return (
    <Stack sx={linkedTasksContainer}>
      <Stack className={'row row__heading'}>
        <TinyText medium>{getTranslationWithValue(0, 'campaign')}</TinyText>
        <TinyText medium justifySelf={'center'}>
          {getTranslationWithValue(0, 'dueDate')}
        </TinyText>
      </Stack>
      <Stack direction={'column'} ref={taskListContainerRef}>
        {response?.map((task, index) => {
          return (
            <Stack
              className={'row row__task'}
              ref={(el: HTMLDivElement) => (tasksRefs.current[index] = el)}
              onClick={() => handleTaskAction(task?.taskUuid)}>
              <Badge
                color={task?.campaignColor}
                badgeLabel={task?.campaignName}
              />
              <TinyText justifySelf={'center'} className={'dueDate'}>
                {task?.dueDateTask
                  ? dayjs(task?.dueDateTask)
                      .utc()
                      .format(
                        dayjs().locale() === 'it'
                          ? 'D MMM YYYY'
                          : 'Do MMM YYYY',
                      )
                  : '-'}
              </TinyText>
              <BsChevronRight fontSize={24} />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
});

LinkedTasksList.displayName = 'LinkedTasksList';
