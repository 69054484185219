import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';

import {Box, useMediaQuery, useTheme, Stack, Skeleton} from '@mui/material';
import {useQuery, useMutation} from '@tanstack/react-query';

import {Avatar} from '@/atoms/Avatar';
import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {LoadingPage} from '@/atoms/LoadingPage';
import {Text} from '@/atoms/Typography/Text';
import {useToast} from '@/hooks/toast';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useParamsAndClean} from '@/hooks/useParamsAndClean';
import {capitalizeWords} from '@/lib/utils';
import {useNavigation} from '@/navigation/useNavigation';
import {AppointmentHistory} from '@/organisms/AppointmentHistory';
import {Benefits} from '@/organisms/Benefits';
import {LinkedTasks} from '@/organisms/LinkedTasks';
import {LoyaltyBox} from '@/organisms/LoyaltyBox';
import {PersonalInformation} from '@/organisms/PersonalInformation';
import {PurchasesDetails} from '@/organisms/PurchasesDetails';
import {Quotes} from '@/organisms/Quotes';
import {
  useAppointmentsV1,
  PostAppointmentHistoryParamsV1,
} from '@/store/appointments/v1';
import {useAuthentication} from '@/store/authentication';
import {useBenefit, GetBenefitsParams} from '@/store/benefit';
import {useCustomerInfo, PostLoyaltyTiersInfoBody} from '@/store/customersInfo';
import {useOrder, GetPurchaseDetailsParams} from '@/store/order';
import {
  usePersonas,
  GetPersonalInformationCustomersParams,
  CustomerResponse,
} from '@/store/personas';
import {useQuotations, GetQuotationsParams} from '@/store/quotations';
import {useTasks} from '@/store/tasks';
import {PostLinkedTasksParams} from '@/store/tasks/types';
import {Base} from '@/templates/Base';
import {Colors} from '@/themes/variables';

import {useStyle} from './styles';

const Customer = () => {
  const {goBack, goToPurchaseList, goToClientResults} = useNavigation();
  const {getTranslationWithValue} = useBaseTranslation('LoyaltyBox');
  const {t} = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  // Header ref
  const headerRef = useRef<HTMLDivElement>();
  const [headerHeight, setHeaderHeight] = useState<number>(60);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Customer detail',
    });
  }, []);

  useEffect(() => {
    const setHeaderHeightLocal = () => {
      if (headerRef?.current) {
        setHeaderHeight(headerRef?.current?.clientHeight);
      }
    };

    setHeaderHeightLocal();

    window.addEventListener('resize', setHeaderHeightLocal);
    const resizeObserver = new ResizeObserver(setHeaderHeightLocal);
    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', setHeaderHeightLocal);
    };
  }, [headerRef]);

  const styles = useStyle();
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerResponse>();

  const {showErrorToast, showSuccessToast} = useToast();
  const {getPersonalInformationCustomers} = usePersonas();
  const {getPurchaseDetails} = useOrder();
  const {getBenefits} = useBenefit();
  const {getQuotations} = useQuotations();
  const {postCustomerinfoSubscribe, postLoyaltyTiersInfo} = useCustomerInfo();
  const {getLinkedTasks} = useTasks();
  const {
    hasLoyalty,
    userCountry,
    appointmentType,
    isContactCenterAgent,
    isContactCenterSupervisor,
    userStoreId,
    userData,
  } = useAuthentication();

  const {getAppointmentHistory} = useAppointmentsV1();

  const isCC = useMemo(() => {
    return isContactCenterAgent || isContactCenterSupervisor;
  }, [isContactCenterAgent, isContactCenterSupervisor]);

  const showAppointments = useMemo(() => {
    return !!appointmentType || isCC;
  }, [appointmentType, isCC]);

  const params = useParamsAndClean(
    'email',
    'customerId',
    'personaChannel',
    'banner',
    'country',
    'showCustomers',
  );

  const paramsRetrieved = useMemo(() => {
    return (
      Object.keys(params).length > 0 &&
      'email' in params &&
      'banner' in params &&
      'country' in params
    );
  }, [params]);

  /**
   * @brief Loyalty tiers info payload
   * @note This payload is used to get loyalty tiers info
   */
  const loyaltyTiersInfoPayload = useMemo<PostLoyaltyTiersInfoBody>(() => {
    return {
      email: params?.email,
      bannerSearch: params?.banner,
      countrySearch: params?.country,
      ...(params?.customerId &&
        params?.personaChannel && {
          customerId: params.customerId,
          personaChannel: params.personaChannel,
        }),
    };
  }, [
    params?.banner,
    params?.country,
    params.customerId,
    params?.email,
    params.personaChannel,
  ]);

  /**
   * @brief Get personal information customers
   * @note This query is used to get personal information customers
   */
  const {
    data: LoyaltyTiersInfoData,
    refetch: LoyaltyTiersInfoRefetch,
    isFetching: LoyaltyTiersInfoLoading,
  } = useQuery(
    ['postLoyaltyTiersInfo', loyaltyTiersInfoPayload],
    () => postLoyaltyTiersInfo(loyaltyTiersInfoPayload),
    {
      enabled: paramsRetrieved && hasLoyalty,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  /**
   * @brief Mutation to handle post customer info subscribe
   */
  const {
    mutate: PostCustomerinfoSubscribe,
    isLoading: PostCustomerinfoSubscribeLoading,
  } = useMutation(postCustomerinfoSubscribe, {
    onSuccess: async () => {
      showSuccessToast(getTranslationWithValue(0, 'toasts.success'));
      await LoyaltyTiersInfoRefetch();
    },
    onError: async () => {
      showErrorToast(getTranslationWithValue(0, 'toasts.error'));
    },
  });

  const personalInformationPayload = useMemo<
    GetPersonalInformationCustomersParams | PostLinkedTasksParams
  >(() => {
    return {
      email: params?.email,
      bannerSearch: params?.banner,
      countrySearch: params?.country,
      ...(params?.customerId &&
        params?.personaChannel && {
          customerId: params.customerId,
          personaChannel: params.personaChannel,
        }),
      ...((!params?.showCustomers ||
        (!!params?.showCustomers && params?.showCustomers === 'false')) && {
        singleCustomerId: true,
      }),
    };
  }, [
    params?.banner,
    params?.country,
    params.customerId,
    params?.email,
    params.personaChannel,
    params.showCustomers,
  ]);

  const {
    data: CustomersData,
    isLoading: CustomersLoading,
    isError: CustomersError,
  } = useQuery(
    ['getPersonalInformationCustomers', personalInformationPayload],
    async () => {
      /**
       * @note If customerId is not found, then we will call the API without customerId
       */
      try {
        return await getPersonalInformationCustomers(
          personalInformationPayload,
        );
      } catch (err) {
        return await getPersonalInformationCustomers({
          ...personalInformationPayload,
          customerId: undefined,
          personaChannel: undefined,
        });
      }
    },
    {
      enabled: paramsRetrieved,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const {data: LinkedTasksData, isFetching: LinkedTasksLoading} = useQuery(
    ['getLinkedTasks', personalInformationPayload],
    async () => {
      /**
       * @note If customerId is not found, then we will call the API without customerId
       */
      try {
        return await getLinkedTasks(personalInformationPayload);
      } catch (err) {
        return await getLinkedTasks({
          ...personalInformationPayload,
          customerId: undefined,
          personaChannel: undefined,
        });
      }
    },
    {
      enabled: paramsRetrieved,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const purchaseDetailsPayload = useMemo<GetPurchaseDetailsParams>(() => {
    return {
      email: params?.email,
      bannerSearch: params?.banner,
      countrySearch: params?.country,
    };
  }, [params?.banner, params?.country, params?.email]);

  const {data: PurchaseDetailsData} = useQuery(
    ['getPurchaseDetails', purchaseDetailsPayload],
    () => getPurchaseDetails(purchaseDetailsPayload),
    {
      enabled: paramsRetrieved,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const quotationsPayload = useMemo<GetQuotationsParams>(() => {
    return {
      email: params?.email,
      bannerSearch: params?.banner,
      countrySearch: params?.country,
    };
  }, [params?.banner, params?.country, params?.email]);

  const {data: QuotationsData} = useQuery(
    ['getQuotations', quotationsPayload],
    () => getQuotations(quotationsPayload),
    {
      enabled: paramsRetrieved,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const benefitsPayload = useMemo<GetBenefitsParams>(() => {
    return {
      email: params?.email,
      bannerSearch: params?.banner,
      countrySearch: params?.country,
    };
  }, [params?.banner, params?.country, params?.email]);

  const {data: BenefitsData} = useQuery(
    ['getBenefits', benefitsPayload],
    () => getBenefits(benefitsPayload),
    {
      enabled: paramsRetrieved,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  /**
   * @brief AppointmentHistoryPayload payload
   * @note This payload is used to get appointment history
   */
  const getAppointmentHistoryPayload =
    useMemo<PostAppointmentHistoryParamsV1>(() => {
      return {
        email: params?.email ?? '',
        bannerSearch: params?.banner ?? '',
        countrySearch: params?.country ?? '',
      };
    }, [params?.banner, params?.country, params?.email]);

  const {data: AppointmentHistoryData, isFetching: AppointmentHistoryLoading} =
    useQuery({
      queryKey: [
        'getAppointmentHistory',
        getAppointmentHistoryPayload?.email,
        getAppointmentHistoryPayload?.bannerSearch,
        getAppointmentHistoryPayload?.countrySearch,
      ],
      queryFn: () => getAppointmentHistory(getAppointmentHistoryPayload),
      enabled: paramsRetrieved,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      retry: false,
    });

  // Root ref for the container scrollable column
  const rootRef = useRef<HTMLDivElement>();
  // Array of refs for each anchor card
  const itemsRef = useRef<HTMLDivElement[]>([]);
  // Current index of anchor card visible on screen
  const [currentViewIndex, setCurrentViewIndex] = useState<number>(0);

  // Execute a smooth scroll targeting an anchor card
  const executeScroll = useCallback((i: number) => {
    itemsRef.current[i].scrollIntoView({behavior: 'smooth', block: 'start'});
  }, []);

  // List of anchors to display
  const anchors = useMemo(() => {
    return [
      t('MyAccount.Anchors.personalInformation'),
      ...(hasLoyalty ? [t('MyAccount.Anchors.loyalty')] : []),
      t('MyAccount.Anchors.benefits'),
      t('MyAccount.Anchors.linkedTasks'),
      ...(showAppointments ? [t('MyAccount.Anchors.appointments')] : []),
      t('MyAccount.Anchors.quotes'),
    ];
  }, [hasLoyalty, showAppointments, t]);

  // Render each anchor link
  const renderAnchors = useMemo(() => {
    return anchors.map((el, index) => {
      return (
        <Box sx={styles.anchorBox} marginBottom="16px" key={`${el}-${index}`}>
          <Text
            variant="tiny"
            superHeavy
            sx={{textDecoration: 'none', cursor: 'pointer'}}
            onClick={() => executeScroll(index)}
            color={currentViewIndex === index ? Colors.Black : Colors.GreyDark}>
            {el}
          </Text>
        </Box>
      );
    });
  }, [anchors, currentViewIndex, executeScroll, styles.anchorBox]);

  useEffect(() => {
    const scrollContainer = document.getElementById('scrollable-column');
    const lastBox = itemsRef.current[itemsRef.current.length - 1];

    const calculateMargin = () => {
      if (!lastBox) return;

      const containerHeight = scrollContainer!.clientHeight;
      const lastBoxHeight = lastBox.clientHeight;

      const marginBottom = containerHeight - lastBoxHeight;
      lastBox.style.marginBottom = `${marginBottom}px`;
    };

    calculateMargin();

    const resizeObserver = new ResizeObserver(calculateMargin);
    itemsRef.current.forEach(box => resizeObserver.observe(box));

    window.addEventListener('resize', calculateMargin);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', calculateMargin);
    };
  }, [itemsRef]);

  useEffect(() => {
    const container = document.getElementById('scrollable-column');
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleScroll = () => {
    const scrollContainer = document.getElementById('scrollable-column');
    const containerTop = scrollContainer!.getBoundingClientRect().top;

    let closestIndex = 0;
    let closestDistance = Infinity;

    itemsRef.current.forEach((box, index) => {
      const boxTop = box.getBoundingClientRect().top - containerTop!; // Adjusted for scrollable column top
      const distance = Math.abs(boxTop);

      if (distance < closestDistance) {
        closestDistance = distance;
        closestIndex = index;
      }
    });

    setCurrentViewIndex(closestIndex);
  };

  const indexes = useMemo(() => {
    return {
      personalInformation: 0,
      loyalty: 1,
      benefits: hasLoyalty ? 2 : 1,
      linkedTasks: hasLoyalty ? 3 : 2,
      appointments: hasLoyalty && showAppointments ? 4 : 3,
      quotes: hasLoyalty
        ? showAppointments
          ? 5
          : 4
        : showAppointments
        ? 4
        : 3,
    };
  }, [hasLoyalty, showAppointments]);

  return (
    <Base title={t('MyAccount.Header.title')}>
      <>
        {PostCustomerinfoSubscribeLoading && <LoadingPage invisible={false} />}
        {/* Header */}
        <Stack direction="column" flex={1} gap={1.6} overflow="hidden">
          <Box sx={styles.headingGrid} ref={headerRef}>
            <Link
              linkVariant="dark"
              textVariant="body"
              href="#"
              handleOnClick={() => {
                if (
                  !!params?.showCustomers &&
                  params?.showCustomers === 'true'
                ) {
                  goBack();
                } else {
                  goToClientResults(
                    params?.email!,
                    params?.banner!,
                    params?.country!,
                  );
                }
              }}
              iconStart={
                <IconSVG icon="arrow_left_2" size={16} color="#000" />
              }>
              {t('Generic.ctas.back')}
            </Link>
            {typeof selectedCustomer === 'undefined' ? (
              <Skeleton
                height="60px"
                width="50%"
                animation="wave"
                variant="rounded"
              />
            ) : (
              <Box sx={styles.heading}>
                <Box flexShrink={0}>
                  <Avatar
                    big
                    name={capitalizeWords(selectedCustomer?.firstName!)}
                    surname={capitalizeWords(selectedCustomer?.lastName!)}
                    variant="dark"
                  />
                </Box>
                <Text variant="headline3" superHeavy>
                  {`${capitalizeWords(
                    selectedCustomer?.firstName!,
                  )} ${capitalizeWords(selectedCustomer?.lastName!)}`}
                </Text>
                <Text variant="body" medium>
                  {selectedCustomer?.email ?? '-'}
                </Text>
              </Box>
            )}
          </Box>
          {/* Grid */}
          <Box sx={styles.grid(headerHeight)}>
            {/* Anchors - 1st column */}
            <Box>
              <Text variant="caption" superHeavy uppercase>
                {t('MyAccount.Anchors.title')}
              </Text>
              <Box sx={styles.anchors}>{renderAnchors}</Box>
            </Box>
            {/* Content / Scrollable column - 2nd column */}
            <Box
              id="scrollable-column"
              ref={rootRef}
              sx={{overflowY: 'scroll', scrollbarWidth: 'none'}}>
              <Stack direction="column" gap={1.6}>
                <Box
                  ref={(el: HTMLDivElement) =>
                    (itemsRef.current[indexes.personalInformation] = el)
                  }>
                  <PersonalInformation
                    error={CustomersError}
                    selectedCustomerCb={customer =>
                      setSelectedCustomer(customer)
                    }
                    enableCustomersList={
                      !!params?.showCustomers &&
                      params?.showCustomers === 'true'
                    }
                    customersResponse={CustomersData?.customers ?? []}
                    loading={CustomersLoading}
                    {...(!params.showCustomers && {
                      viewAllProfileCB: () =>
                        goToClientResults(
                          params?.email!,
                          params?.banner!,
                          params?.country!,
                        ),
                    })}
                  />
                </Box>
                <HeadlineText heavy marginTop={2.4}>
                  {t('MyAccount.informationFromAllProfiles')}
                </HeadlineText>
                {/* On tablet portrait show purchase details in this column as second element */}
                {!isDesktop ? (
                  <Box>
                    <PurchasesDetails
                      country={userCountry!}
                      response={PurchaseDetailsData}
                      viewPurchaseHistoryCB={() =>
                        goToPurchaseList(
                          `${capitalizeWords(
                            selectedCustomer?.firstName!,
                          )} ${capitalizeWords(selectedCustomer?.lastName!)}`,
                          params.email,
                          params.banner,
                          params.country,
                        )
                      }
                    />
                  </Box>
                ) : (
                  <></>
                )}
                {hasLoyalty && (
                  <Box
                    ref={(el: HTMLDivElement) =>
                      (itemsRef.current[indexes.loyalty] = el)
                    }>
                    <LoyaltyBox
                      origin={'customer'}
                      {...(CustomersData?.customers && {
                        customer: CustomersData?.customers[0],
                      })}
                      loading={LoyaltyTiersInfoLoading}
                      response={LoyaltyTiersInfoData}
                      subscribeCB={PostCustomerinfoSubscribe}
                      bannerSearch={params?.banner!}
                      countrySearch={params?.country!}
                    />
                  </Box>
                )}
                <Box
                  ref={(el: HTMLDivElement) =>
                    (itemsRef.current[indexes.benefits] = el)
                  }>
                  <Benefits response={BenefitsData} />
                </Box>
                <Box
                  ref={(el: HTMLDivElement) =>
                    (itemsRef.current[indexes.linkedTasks] = el)
                  }>
                  <LinkedTasks
                    response={LinkedTasksData}
                    loading={LinkedTasksLoading}
                  />
                </Box>
                {showAppointments && (
                  <Box
                    ref={(el: HTMLDivElement) =>
                      (itemsRef.current[indexes.appointments] = el)
                    }>
                    <AppointmentHistory
                      readOnly={isCC}
                      loading={AppointmentHistoryLoading}
                      response={AppointmentHistoryData}
                      {...(CustomersData?.customers && {
                        customer: CustomersData?.customers[0],
                      })}
                      taskHeaderData={{
                        storeId: userStoreId,
                        banner: params?.banner,
                        country: params?.country,
                      }}
                      currentStoreId={userData?.currentStore?.storeId}
                    />
                  </Box>
                )}
                <Box
                  ref={(el: HTMLDivElement) =>
                    (itemsRef.current[indexes.quotes] = el)
                  }>
                  <Quotes response={QuotationsData} />
                </Box>
              </Stack>
            </Box>
            {/* Purchase Details - 3rd column - show only if resolution is > tablet portrait */}
            {/* If the resolution is < than the tablet portrait, it'll show the card in the second column */}
            {isDesktop ? (
              <Box className="purchaseDetailsRight">
                <PurchasesDetails
                  country={userCountry!}
                  response={PurchaseDetailsData}
                  viewPurchaseHistoryCB={() =>
                    goToPurchaseList(
                      `${capitalizeWords(
                        selectedCustomer?.firstName!,
                      )} ${capitalizeWords(selectedCustomer?.lastName!)}`,
                      params.email,
                      params.banner,
                      params.country,
                    )
                  }
                />
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Stack>
      </>
    </Base>
  );
};

export default React.memo(Customer);
